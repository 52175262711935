<div class="form-container">
  <div class="header-container">
    <div class="close-icon" (click)="closePopup()"><img src="assets/svg/close.svg" class="icon"/></div>
    <div class="status-label"> Edit Status for {{programName}}</div>
  </div>

  <div class="status-outer-container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="status-dropdown-container">
      <label class="status-dropdown-label">Status</label>
      <nb-select class="input-team" placeholder="Select Status" (selectedChange)='onStatusChange($event)' formControlName="currentStatus">
        <nb-option value="" disabled>Select Status</nb-option>
        <nb-option class='option' *ngFor="let eachStatus of statusOptions" [value]="eachStatus">{{ eachStatus }}</nb-option>
      </nb-select>
    <div *ngIf="form.get('currentStatus').invalid && form.get('currentStatus').touched" class="error">
      Please select a status.
    </div>
    <div *ngIf="errorMessage" class="form-error">
      {{ errorMessage }}
    </div>
    </div>


      <div *ngIf="showKPIs" >
        <div class="description-container">
          <label class="description-label">Description</label>
          <textarea class="description-text-area" id="description" formControlName="description" rows="4" cols="50"></textarea>
          <span class="help-text"><img
            src="assets/svg/feature-i-icon.svg" class="icon" />{{DESCRIPTION_INFO_MESSAGE}}</span>
        </div>
    
        <div class="checkbox-container-outer">
          <label class="kpi-label">KPIs</label>
          <div  class="checkbox-container">
            <div *ngFor="let option of KPI_NAMES" class="checkbox-item">
              <input 
                type="checkbox"
                [value]="option.value"
                (change)="onCheckboxChange($event)"
                [checked]="selectedOptions.value.includes(option.value)" />
              <label class="label">{{ option.label }}</label>
            </div>
          </div>
          <div *ngIf="selectedOptions.invalid && selectedOptions.touched" class="error">
            At least one option must be selected.
          </div>
          
        </div>
      </div>
    </form>
  </div>

  <div class="footer-container">
    <button class="cancel-button" type="button" (click)="closePopup()">Cancel</button>
    <button class="submit-button" type="submit" [disabled]="form.invalid" (click)="onSubmit()">Submit</button>
  </div>

  <div class="spinner-overlay" *ngIf="loading">
    <div id="nb-global-spinner" class="spinner">
        <div class="blob blob-0"></div>
        <div class="blob blob-1"></div>
        <div class="blob blob-2"></div>
        <div class="blob blob-3"></div>
        <div class="blob blob-4"></div>
        <div class="blob blob-5"></div>
    </div>
</div>
</div>