import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusBarService } from '../status-bar.service';
import { SeDashboardMetricsService } from 'src/app/se-metrics/se-metrics.service';
import { ALERT_METRIC_TYPE, APP_RESPONSE_TIME, AUTOMATION_TEXT_COVERAGE_TITLE, AVAILABILITY, CHANGE_FAIL_RATE, CURRENT_STATUS, DEPLOYMENT_FREQUENCY, ESCALATED, ESCALATED_STATUS, FLAG_FOR_REVIEW_STATUS, LEAD_TIME_TITLE, MEAN_TIME_TO_RESOLVE, MESSAGE, NOT_VALIDATED, NOT_VALIDATED_STATUS, PENDING_REVIEW, PENDING_REVIEW_STATUS, READY_FOR_VALIDATION, READY_FOR_VALIDATION_STATUS, REUSABILITY_TITLE, UNDER_ANALYSIS, UNDER_ANALYSIS_STATUS, UNIT_TEST_COVERAGE, VALIDATED, VALIDATED_STATUS, VALIDATION_PENDING, VALIDATION_PENDING_STATUS } from '../../app-constants';
import { SEMetricsComponent } from 'src/app/se-metrics/se-metrics.component';
import { convertEnumToString } from '../../app-utility';

@Component({
  selector: 'app-escalate-semetric-issue-popup',
  templateUrl: './escalate-semetric-issue-popup.component.html',
  styleUrls: ['./escalate-semetric-issue-popup.component.scss']
})
export class EscalateSemetricIssuePopupComponent implements OnInit {
  form: FormGroup;
  selectedStatus: string = '';
  showKPIs: Boolean = false;
  errorMessage: string = '';
  statusOptions: String[] = [];
  loading: boolean = false;
  readonly DESCRIPTION_INFO_MESSAGE = 'Please provide a brief description of the issue.';

  KPI_NAMES = [
    { label: DEPLOYMENT_FREQUENCY, value: 'DEPLOYMENT_FREQUENCY' },
    { label: AUTOMATION_TEXT_COVERAGE_TITLE, value: 'TEST_AUTOMATION' },
    { label: UNIT_TEST_COVERAGE, value: 'UNIT_TEST_COVERAGE' },
    { label: APP_RESPONSE_TIME, value: 'APP_RESPONSE_TIME' },
    { label: REUSABILITY_TITLE, value: 'REUSABILITY' },
    { label: AVAILABILITY, value: 'AVAILABILITY' },
    { label: LEAD_TIME_TITLE, value: 'LEAD_TIME_TO_CHANGE' },
    { label: CHANGE_FAIL_RATE, value: 'CHANGE_FAIL_RATE' },
    { label: MEAN_TIME_TO_RESOLVE, value: 'MEAN_TIME_TO_RESOLVE' }
  ];

  @Input() selectStatus: Function;
  @Input() currentStatus: any;
  @Input() validationStatus: any;
  @Input() updateStatus: Function;
  @Input() programName: string;

  constructor(public fb: FormBuilder, public activeModal: NgbActiveModal, private statusService: StatusBarService, private seDashboardMetricsService: SeDashboardMetricsService) { }

  ngOnInit() {
    this.form = this.fb.group({
      currentStatus: ['', Validators.required],
      description: ['', Validators.required],
      selectedOptions: this.fb.array([], Validators.required),
    });
    this.statusOptions = this.fetchAvailableOptions(this.currentStatus);
  }

  fetchAvailableOptions = (status) => {
    let statusOptions = [];
    if (status === READY_FOR_VALIDATION_STATUS) {
      statusOptions = [VALIDATED_STATUS, FLAG_FOR_REVIEW_STATUS];
      return statusOptions;
    } else if (status === VALIDATED_STATUS || status === NOT_VALIDATED_STATUS) {
      return statusOptions;
    } else if(status === VALIDATION_PENDING_STATUS){
      statusOptions = [VALIDATED_STATUS, FLAG_FOR_REVIEW_STATUS];
      return statusOptions;
    } else if(status === ESCALATED_STATUS){
      statusOptions = [VALIDATED_STATUS, FLAG_FOR_REVIEW_STATUS];
      return statusOptions;
    }else if (status === PENDING_REVIEW_STATUS) {
      statusOptions = [UNDER_ANALYSIS_STATUS];
      return statusOptions;
    } else if (status === UNDER_ANALYSIS_STATUS) {
      statusOptions = [VALIDATED_STATUS];
      return statusOptions;
    }
    return statusOptions;
  }

  get selectedOptions(): FormArray {
    return this.form.get('selectedOptions') as FormArray;
  }

  onStatusChange(status: any) {
    const currentStatusControl = this.form.get(CURRENT_STATUS);
    currentStatusControl.setValue(status);
    currentStatusControl.markAsTouched();
  
    const descriptionControl = this.form.get('description');
    const selectedOptionsControl = this.form.get('selectedOptions');

    if (status === FLAG_FOR_REVIEW_STATUS) {
      this.showKPIs = true;
      descriptionControl.setValidators(Validators.required);
      selectedOptionsControl.setValidators(Validators.required);
    } else {
      this.showKPIs = false;
      descriptionControl.clearValidators();
      selectedOptionsControl.clearValidators();
    }
    this.selectedStatus = status === FLAG_FOR_REVIEW_STATUS ? PENDING_REVIEW_STATUS :  status;
    this.form.get(CURRENT_STATUS).setValue(status);
    this.form.get(CURRENT_STATUS).markAsTouched();
    descriptionControl.updateValueAndValidity();
    selectedOptionsControl.updateValueAndValidity();
  }

  onCheckboxChange(event: any) {
    const checkbox: FormArray = this.selectedOptions;
    if (event.target.checked) {
      checkbox.push(this.fb.control(event.target.value));
    } else {
      const index = checkbox.controls.findIndex(
        (control) => control.value === event.target.value
      );
      if (index !== -1) {
        checkbox.removeAt(index);
      }
    }
  }

  public onSubmit() {
    if (!this.validationStatus || !this.validationStatus.productDashboardId) {
      this.errorMessage = 'Missing required information. Please try again.';
      return; // Early exit if validation status is invalid
    }
    this.loading = true;
    const requestBody = { from: this.convertIntoEnum(this.currentStatus), to: this.convertIntoEnum(this.selectedStatus) };

    if (this.selectedStatus === PENDING_REVIEW_STATUS) {
      requestBody[MESSAGE] = this.form.get('description').value;
      requestBody[ALERT_METRIC_TYPE] = this.selectedOptions.value;
    }

    this.seDashboardMetricsService.invalidateCache(this.validationStatus.productDashboardId, 
      this.validationStatus.month,
      this.validationStatus.year);


    const apiCall = this.statusService.transitionAlert(
      this.validationStatus.productDashboardId,
      this.validationStatus.month,
      this.validationStatus.year,
      requestBody
    );
  
    apiCall.subscribe({
      next: () => {
        this.seDashboardMetricsService.fetchSeMetricsOverallData(
          this.validationStatus.productDashboardId, 
          this.validationStatus.month,
          this.validationStatus.year, 
          true
        ).subscribe(res => {
          if(res?.alert){
            const validationStatusString = convertEnumToString(res?.alert?.validationStatus);
            this.selectStatus(validationStatusString);
            this.updateStatus(validationStatusString);
          }
          this.loading = false;
          this.activeModal.close();
        }, (err) => {
          this.loading = false;
          console.error('Failed to fetch SE metrics data:', err);
          this.errorMessage = 'Failed to fetch metrics. Please try again.';
        });
      },
      error: (err) => {
        console.error('Failed to update status:', err);
        this.errorMessage = err?.error || 'Failed to update status. Please try again.';
        this.activeModal.dismiss(err?.error || 'Failed to update status. Please try again.');
      },
    });
  }

  convertIntoEnum(status) {
    switch (status) {
      case READY_FOR_VALIDATION_STATUS:
        return READY_FOR_VALIDATION;
      case VALIDATED_STATUS:
        return VALIDATED;
      case VALIDATION_PENDING_STATUS:
        return VALIDATION_PENDING;
      case ESCALATED_STATUS:
        return ESCALATED;
      case NOT_VALIDATED_STATUS:
        return NOT_VALIDATED;
      case PENDING_REVIEW_STATUS:
        return PENDING_REVIEW;
      case UNDER_ANALYSIS_STATUS:
        return UNDER_ANALYSIS;
    }
  }
  

  closePopup() {
    this.errorMessage = '';
    this.activeModal.close();
  }
}