import { Injectable, Output, EventEmitter } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// 3rd party import
import { JwtHelperService } from '@auth0/angular-jwt';

// local imports
import { IUserLogin } from '../../shared/interfaces';
import { IUser } from '../../shared/interfaces';
import {environment} from "../../../environments/environment";

const helper = new JwtHelperService();
const httpOptions = {
  headers: new HttpHeaders({
    Accept: 'application/json;v=1',
    'Content-Type': 'application/x-www-form-urlencoded'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string;
  appRoot = environment.appRoot;

  @Output() authChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private http: HttpClient) {}

  getAuthenticationProviders(): any {
    const authenticationProvidersRoute = this.appRoot+'/api/authenticationProviders';
    return this.http.get( authenticationProvidersRoute, httpOptions );
  }
  register(userLogin: IUserLogin): Observable<boolean> {
    return this.http.post(this.appRoot+`/api/registerUser`, userLogin,
      { observe: 'response'})
      .pipe(
        map(loggedIn => {
          const token = loggedIn.headers.get('x-authentication-token');
          localStorage.setItem('access_token', token);
          this.updateUserData();

          if (token) {
            return true;
          }
        })
      );
  }
  loginLdap(userLogin: IUserLogin): Observable<boolean> {
    return this.callLogin(userLogin, this.appRoot+`/api/login/ldap`);
  }

  login(userLogin: IUserLogin): Observable<boolean> {
    return this.callLogin(userLogin, this.appRoot+`/api/login`);
  }

  private callLogin(userLogin: IUserLogin, path: string): Observable<boolean> {
    const params = new HttpParams({
      fromObject: {
        username: userLogin.username,
        password: userLogin.password,
      }
    });
    return this.http.post<boolean>( path,
      params,
      { headers: {'Content-Type': 'application/x-www-form-urlencoded'}, observe: 'response'})
      .pipe(
        map(loggedIn => {
          const token = loggedIn.headers.get('x-authentication-token');
          localStorage.setItem('access_token', token);
          this.updateUserData();
          if (token) {
            return true;
          }
        })
      );
  }

  public getToken(): string {
    // const oktaStorage =  localStorage.getItem('access_token')
   
    return localStorage.getItem('access_token');
    // return JSON.parse(oktaStorage).accessToken.accessToken;
  }

  public updateUserData(): void {
    const userData = {
      gpid: this.getGPID() || "",
      email: this.getUserEmail() || "",
      FirstName: this.getFirstName() || "",
      LastName: this.getLastName()|| "",
      userId :this.getUserId() || "",
      language: navigator?.language,
  };
  (window as any).customUserData = {
    ...(window as any).customUserData,
    ...userData,
  };   
  }

  getAcessToken(): any {
    const accessToken = this.appRoot+'/api/login';
    return this.http.post( accessToken, httpOptions );
  }

  public getOktaToken(): string {
    const oktaStorage =  localStorage.getItem('okta-token-storage')

    return JSON.parse(oktaStorage)?.accessToken?.accessToken;
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('auth-code');
  }

  public getUserName(): string {
    return this.getUser().sub;
  }

  public getExpiration(): number {
    return this.getUser().exp;
  }

  public getGPID(): string {
    return this.getUser().details?.GPID;
  }

  public getUserEmail(): string {
    return this.getUser().details?.email
  }

  public getFirstName(): string {
    return this.getUser().details?.FirstName;
  }

  public getLastName(): string {
    return this.getUser().details?.LastName;
  }

  public getUserId(): string {
    return this.getUser().details?.uid;
  }

  public getRoles(): Array<string> {
    return this.getUser().roles
  }

  public isAdmin(): boolean {
    const user = this.getUser();
    return user.roles && user.roles.indexOf('ROLE_ADMIN') > -1;
  }

  public getAuthType(): string {
    return this.getUser().details?.AuthType;
  }

  public isAuthenticated(): boolean {
    if (!helper.isTokenExpired( this.getOktaToken() ) && this.getUserName() ) {
      return true;
    }

    return false;
  }

  private getUser(): IUser {
    const token = this.getToken();
    if (token) {
      return helper.decodeToken(this.getToken());
    } else {
      return {} as IUser;
    }
  }

  getAuthCode(): string {
    return localStorage.getItem('auth-code');
  }

  isSsoLogin(req: HttpRequest<any>): boolean {
    return (req.url === this.appRoot+'/api/login/openid' && !!this.getAuthCode());
  }
}
