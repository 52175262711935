import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EscalateSemetricIssuePopupComponent } from './escalate-semetric-issue-popup/escalate-semetric-issue-popup.component';
import { ESCALATED_STATUS, NOT_VALIDATED_STATUS, PENDING_REVIEW_STATUS, READY_FOR_VALIDATION_STATUS, UNDER_ANALYSIS_STATUS, VALIDATED_STATUS, VALIDATION_PENDING_STATUS } from '../app-constants';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent {  
  @Input() currentStatus: any;
  @Input() validationStatus: any;
  @Input() updateStatus: (status: String)=> any;
  @Input() programName: string;
  dropdownOpen: boolean = false;
  modalOpen: boolean = false;
  users: any[] = [];
  statuses: string[] = [
    READY_FOR_VALIDATION_STATUS,
    VALIDATED_STATUS,
    VALIDATION_PENDING_STATUS,
    ESCALATED_STATUS,
    NOT_VALIDATED_STATUS,
    PENDING_REVIEW_STATUS,
    UNDER_ANALYSIS_STATUS
  ];
  tooltipMessage: string = 'Edit Status';

  constructor(private modalService: NgbModal, private auth: AuthService) {}

  get isAdmin(): boolean {
    return this.auth.isAdmin();
  }

  toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      if (this.dropdownOpen) {
        const modalRef = this.modalService.open(EscalateSemetricIssuePopupComponent);
        modalRef.componentInstance.selectStatus = this.selectStatus.bind(this);
        modalRef.componentInstance.currentStatus = this.currentStatus;
        modalRef.componentInstance.validationStatus = this.validationStatus;
        modalRef.componentInstance.programName = this.programName;
        modalRef.componentInstance.updateStatus = this.updateCurrentStatus.bind(this);
      }
  }

  updateCurrentStatus = (status: string) => {
    this.updateStatus && this.updateStatus(status);
  }

  getStatusClass(): string {
    switch (this.currentStatus) {
      case READY_FOR_VALIDATION_STATUS: 
        return 'status-sky-blue';
      case VALIDATED_STATUS: 
        return 'status-green';
      case VALIDATION_PENDING_STATUS: 
        return 'status-amber';
      case ESCALATED_STATUS: 
        return 'status-dark-red';
      case NOT_VALIDATED_STATUS: 
        return 'status-red';
      case PENDING_REVIEW_STATUS: 
        return 'status-grey';
      case UNDER_ANALYSIS_STATUS: 
        return 'status-dark-grey';
      default: 
        return 'status-white';
    }
  }

  selectStatus(status: string) {
    this.currentStatus = status;
    this.dropdownOpen = false; 
  }

  openModal() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
  }
  
}
