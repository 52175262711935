import { Component, Input } from '@angular/core';
import { SortEvent } from 'primeng/api/sortevent';
import { ITreeTableData } from './tree-table-generic.interface';

@Component({
  selector: 'app-tree-table-generic',
  templateUrl: './tree-table-generic.component.html',
  styleUrls: ['./tree-table-generic.component.scss']
})
export class TreeTableGenericComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() data: ITreeTableData;
  @Input() customSort: (event: SortEvent)=> any;
  @Input() redirectionCallback: (id: any, name: string)=> any;
  @Input() updateRowData: (rowData:any, status:any)=> any;
  @Input() rowData: any;

  constructor(){}

  handleSortFunction = (event: any) => {
    if (this.data.sortable && this.customSort) {
      this.customSort(event);
    }
  }

  onLinkItemClick = (id: any, name: string) => {
    this.redirectionCallback(id, name);
  }

  updateStatus = (status:String) =>{ 
    this.updateRowData(this.rowData, status);
  }
}
