    <nb-card-body class="main-container">
        <div class="ui-form-upload">MANUAL DATA UPLOAD</div>
        <div class="step-one-info-message typography">Fill details to add manual data</div>

        <form [formGroup]="programManualDataConfigForm" (ngSubmit)="formSubmit()">
            <div class="form-row">
                <div class="left-group">
                    <label class="label">Program Name<span class="required-text">
                            *</span></label>
                    <select class="input-program" formControlName="programName" required (change)="onProgramMonthYearChange()">
                        <option value="" disabled selected>Select a program</option>
                        <option *ngFor="let program of programs" [value]="program">{{ program }}</option>
                    </select>
                </div>
                <div class="right-group">
                    <div>
                        <label class="label">Select Month<span class="required-text"> *</span></label>
                        <select formControlName="month" id="month" class="input-program" (change)="onProgramMonthYearChange()">
                            <option value="" disabled selected>Select a month</option>
                            <option *ngFor="let month of availableMonths" [value]="month">{{ month }}</option>
                        </select>
                    </div>
                    <div>
                        <div class="label">Select Year<span class="required-text"> *</span></div>
                        <select formControlName="year" id="year" class="input-program" (change)="onProgramMonthYearChange()">
                            <option value="" disabled selected>Select year</option>
                            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div *ngIf="!isSubmitDisabled">
            <div class="selectKPI">Select KPIs for which manual data needs to be added:</div>
            <div class="form-row kpi-selection">
                <div *ngFor="let kpi of kpiList; let i = index">
                    <label class="kpi-label">
                        <input type="checkbox" [formControlName]="'kpi_' + i" (change)="onKpiSelectionChange(i)">
                        <span>{{ kpi.name }}</span>
                    </label>
                </div>
            </div>

            <div *ngFor="let kpi of selectedKpis" class="form-row kpi-data">
                <ng-container *ngIf="kpi.name === 'Deployment Frequency' || kpi.name === 'Change Fail Rate'">
                    <label class="label">Deployment Frequency & Change Fail Rate</label>
                    <label class="label">Prod Deployment (via mainline/production branch)</label>
                    <input class="input-program" type="number" [formControlName]="'scheduledDeployment'"
                        placeholder="E.g 15">
                    <label class="label">Hotfix Deployment (via hotfix branch)</label>
                    <input class="input-program" type="number" [formControlName]="'hotfixDeployment'"
                        placeholder="E.g 10">
                </ng-container>

                <ng-container *ngIf="kpi.name === 'Unit Test Coverage'">
                    <label class="label">{{ kpi.name }} (%)</label>
                    <input class="input-program" type="number" [formControlName]="'kpiData_' + kpi.index"
                    placeholder="E.g 50">
                </ng-container>

                <ng-container *ngIf="kpi.name === 'App Response Time'">
                    <label class="label">{{ kpi.name }} (ms)</label>
                    <input class="input-program" type="number" [formControlName]="'kpiData_' + kpi.index"
                    placeholder="E.g 50">
                </ng-container>

                <ng-container *ngIf="kpi.name === 'Lead Time To Change'">
                    <label class="label">{{ kpi.name }} (days)</label>
                    <input class="input-program" type="number" [formControlName]="'kpiData_' + kpi.index"
                    placeholder="E.g 50">
                </ng-container>

                <ng-container *ngIf="kpi.name === 'Mean time to resolve'">
                    <label class="label">{{ kpi.name }} (hrs)</label>
                    <input class="input-program" type="number" [formControlName]="'kpiData_' + kpi.index"
                    placeholder="E.g 50">
                </ng-container>

                <ng-container *ngIf="kpi.name === 'Availability'">
                    <label class="label">{{ kpi.name }} (%)</label>
                    <input class="input-program" type="number" [formControlName]="'kpiData_' + kpi.index"
                    placeholder="E.g 50">
                </ng-container>

                <ng-container *ngIf="kpi.name === 'Software Reusability'">
                    <label class="label">{{ kpi.name }}</label>
                    <label class="label">Global Story Points</label>
                    <input class="input-program" type="number" [formControlName]="'globalStoryPoints'"
                        placeholder="E.g 15">
                    <label class="label">Total Story Points</label>
                    <input class="input-program" type="number" [formControlName]="'totalStoryPoints'"
                        placeholder="E.g 20">
                </ng-container>

                <ng-container *ngIf="kpi.name === 'Automation Test Coverage'">
                    <label class="label">{{ kpi.name }}</label>
                    <label class="label">Automated Test Cases</label>
                    <input class="input-program" type="number" [formControlName]="'automatedTestCases'"
                        placeholder="E.g 25">
                    <label class="label">Total Test Cases</label>
                    <input class="input-program" type="number" [formControlName]="'totalTestCases'"
                        placeholder="E.g 32">
                </ng-container>
            </div>
        </div>
        </form>
        <div *ngIf="validationMessage" class="validation-message">{{ validationMessage }}</div>
        <div class="step-controls">
            <button class="next-btn secondary" (click)="cancel()">Cancel</button>
            <button class="next-btn" (click)="formSubmit()" [disabled]="isSubmitDisabled">Submit</button>
        </div>
    </nb-card-body>