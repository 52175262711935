<div class="title d-flex row">
    <h5 class="col-6 header">Executive Dashboard</h5>

    <div class="repo-header col-6" *ngIf="selectedTab === 'tabSeMetrics'">
        <div class="div">
            <select class="select-option" (change)="onMonthChange($event)" [(ngModel)]="selectedMonthValue">
                <option class="option" *ngFor="let month of monthOptions" [value]="month.value">
                  {{ month.label }}
                </option>
              </select>
            <select class="select-option" (change)="onYearChange($event)" [(ngModel)]="selectedYear">
                <option class="option" *ngFor="let year of yearOptions" [value]="year">
                  {{ year }}
                </option>
              </select>
    </div>
        <a class="link" (click)="openDialog()">
            Need help on SE Metrics?
        </a>
    </div>
</div>

<div class="tab-container">
    <button class="tab-button" (click)="openTab('tabSeMetrics')" [class.active]="selectedTab === 'tabSeMetrics'">SE
        Metrics</button>
    <button class="tab-button" (click)="openTab('tabSummary')"
        [class.active]="selectedTab === 'tabSummary'">Cost</button>
</div>

<div class="spinner-overlay" *ngIf="loading">
    <div id="nb-global-spinner" class="spinner">
        <div class="blob blob-0"></div>
        <div class="blob blob-1"></div>
        <div class="blob blob-2"></div>
        <div class="blob blob-3"></div>
        <div class="blob blob-4"></div>
        <div class="blob blob-5"></div>
    </div>
</div>

<div class="tab-content" [class.loading]="loading">
    <div id="tabSeMetrics" class="tab-pane" [class.active]="selectedTab === 'tabSeMetrics'">
    <span class="label-view"><span class="sign">*</span>KPI needs to be automated. Manual data uploaded.</span>
    <span class="label-view-exclamation"><span class="exclamation">!</span>Service needs to be configured.</span>
  <div *ngFor="let eachProgramData of categoryProgramList; let i = index">
    <div class="table-title">
      <h5>{{ eachProgramData?.title }}</h5>
    </div>
    <div class="table-component">
      <ng-container *ngIf="noDataAvailable; else showTable">
        <div class="noDataAvailable">{{ noDataMessage }}</div>
      </ng-container>
      <ng-template #showTable>
            <app-tree-table-generic *ngIf="i==0" [data]="eachProgramData?.data" [customSort]="customSort" [redirectionCallback]="redirectionFunction" [updateRowData]="updateRowData"></app-tree-table-generic>
           <app-table-view *ngIf="i>0" [data]="eachProgramData?.data" [customSort]="customSort" [redirection]="redirectionFunction" [updateRowData]="updateRowData"></app-table-view>
      </ng-template>
    </div>
</div>

<div class="add-manual-data">
    <p class="add-manual-data-text">
        <b>NOTE:</b> For programs whose KPIs are not reflecting correctly because of process adherence issues, we have provided the option to input data manually. This is a temporary feature and will be deprecated soon. Please <span class="clickable-text" (click)="addManualData()"> click here  </span>
        to add data manually for any KPI of your program.
      </p>
        </div>
    </div>
    <div id="tabSummary" class="tab-pane" [class.active]="selectedTab === 'tabSummary'">
        <app-cost></app-cost>
    </div>
</div>


<div class="dialog-wrapper">
    <div class="dialog-box" [ngClass]="{ 'show-dialog': isDialogOpen }">
        <div class="dialog-text-section">
            <span class="dialog-text">{{ DIALOG_TITLE_TEXT }}</span>
        </div>
       
        <div class="dialog-content-section">
            <span class="">For more details, please refer to our           
                <a class="wiki-link" target="blank" href ={{wikiLink}}>wiki page</a>
            </span>
            <span class="dialog-text-content"></span>
            <div *ngFor="let kpiInfo of KpiDialogInfos">
                <span class="dialog-text-title">{{ kpiInfo.title}}</span>
                <span class="dialog-text-content">{{ kpiInfo.info }}</span>
            </div>
        </div>
        <span class="close-icon" (click)="closeDialog()">&#10006;</span>
    </div>
</div>