import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { API_PARAM_MONTH, API_PARAM_PRODUCT_DASHBOARD_ID, API_PARAM_YEAR } from '../app-constants';

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {
  appRoot = environment.appRoot;
  private apiBaseUrl = `${this.appRoot}/api/se-metrics/alert`;

  constructor(private http: HttpClient) {}

  transitionAlert(productDashboardId: string, month: number, year: number, dataValidationRequest: any): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.apiBaseUrl}/${productDashboardId}`;
    return this.http.put<string>(
      url,
      dataValidationRequest,
      { headers, params: { month: month.toString(), year: year.toString() } }
    );
  }

  transitionAlertV2(productDashboardId: string, month: number, year: number, dataValidationRequest: any): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.apiBaseUrl}/${productDashboardId}`;
    return this.http.put<string>(
      url,
      dataValidationRequest,
      { headers, params: { month: month.toString(), year: year.toString() } }
    );
  }
}