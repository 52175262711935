import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams} from '@angular/common/http';
import { CollectorService } from 'src/app/shared/collector.service';
import { API_HEADER_NO_CACHED, API_PARAM_MONTH, API_PARAM_PERIOD, API_PARAM_PRODUCT_DASHBOARD_ID, API_PARAM_YEAR } from '../shared/app-constants';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SeDashboardMetricsService {
  appRoot = environment.appRoot;
  private cache: { [key: string]: any } = {};
  private readonly SE_METRICS_API = this.appRoot + '/api/dpsMetrics/period';
  public readonly SE_METRICS_OVERALL_API = this.appRoot + '/api/se-metrics/v2'
  private readonly PRODUCT_DASHBOARD_API = this.appRoot + '/api/productDashboards/';
  private readonly SE_METRICS_MANUAL_API = this.appRoot + '/api/se-metrics/manual';

  constructor(private http: HttpClient,
    private collectorService: CollectorService,) { }

  fetchSeMetrics(month: number, year: number) {
    const params = new HttpParams()
      .set(API_PARAM_MONTH, month)
      .set(API_PARAM_YEAR, year);
    return this.http.get<any>(this.SE_METRICS_API, { params });
  }

  fetchSeMetricsOverallData(productDashboardId:string,month:number, year: number, bypassCache: boolean = false){
    const cacheKey = `${productDashboardId}-${month}-${year}`;

    if (!bypassCache && this.cache[cacheKey]) {
      return of(this.cache[cacheKey]);
    }

    let params = new HttpParams()
      .set(API_PARAM_PRODUCT_DASHBOARD_ID, productDashboardId)
      .set(API_PARAM_MONTH, month)
      .set(API_PARAM_YEAR, year);
      
    if (bypassCache) {
      params = params.set('timestamp', new Date().getTime().toString());
    }

    return this.http.get<any>(this.SE_METRICS_OVERALL_API, { params }).pipe(
      tap(data => {
        this.cache[cacheKey] = data;
      })
    );
  }
  invalidateCache(productDashboardId: string, month: number, year: number): void {
    const cacheKey = `${productDashboardId}-${month}-${year}`;
    delete this.cache[cacheKey];
  }

fetchAllProductDashboards(){
  return  this.http.get<any>(this.PRODUCT_DASHBOARD_API);

}

createManualEntry(request) {
  return this.http.post<any>(this.SE_METRICS_MANUAL_API, request);
}

getManualEntry(productDashboardId: string, period: string) {
  const params = new HttpParams()
    .set(API_PARAM_PRODUCT_DASHBOARD_ID, productDashboardId)
    .set(API_PARAM_PERIOD, period)
    .set(API_HEADER_NO_CACHED, API_HEADER_NO_CACHED);
  return this.http.get<any>(this.SE_METRICS_MANUAL_API, { params });
}

updateManualEntry(request) {
  return this.http.put<any>(this.SE_METRICS_MANUAL_API, request);
}
}