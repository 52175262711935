import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { CaponeTemplateComponent } from "../screen_modules/team-dashboard/capone-template/capone-template.component";
import { ChartDirective } from "./charts/chart.directive";
import { ChartComponent } from "./charts/chart/chart.component";
import { ClickListComponent } from "./charts/click-list/click-list.component";
import { ComboChartComponent } from "./charts/combo-chart/combo-chart.component";
import { ComboSeriesVerticalComponent } from "./charts/combo-series-vertical/combo-series-vertical.component";
import { GaugeChartComponent } from "./charts/gauge-chart/gauge-chart.component";
import { LineAndBarChartComponent } from "./ngx-charts/line-and-bar-chart/line-and-bar-chart.component";
import { LineChartComponent } from "./charts/line-chart/line-chart.component";
import { NumberCardChartComponent } from "./charts/number-card-chart/number-card-chart.component";
import { PlainTextChartComponent } from "./charts/plain-text-chart/plain-text-chart.component";
import { DashStatusComponent } from "./dash-status/dash-status.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LayoutDirective } from "./layouts/layout.directive";
import { LayoutComponent } from "./layouts/layout/layout.component";
import { TwoByTwoLayoutComponent } from "./layouts/two-by-two-layout/two-by-two-layout.component";
import { ConfirmationModalComponent } from "./modals/confirmation-modal/confirmation-modal.component";
import { DetailModalComponent } from "./modals/detail-modal/detail-modal.component";
import { DetailModalDirective } from "./modals/detail-modal/detail-modal.directive";
import { FormModalComponent } from "./modals/form-modal/form-modal.component";
import { FormModalDirective } from "./modals/form-modal/form-modal.directive";
import { PaginationComponent } from "./pagination/pagination.component";
import { MinutesPipe } from "./pipes/minutes.pipe";
import { BaseTemplateComponent } from "./templates/base-template/base-template.component";
import { TemplatesDirective } from "./templates/templates.directive";
import { WidgetHeaderComponent } from "./widget-header/widget-header.component";
import { PlaceholderWidgetComponent } from "./widget/placeholder-widget/placeholder-widget.component";
import { WidgetComponent } from "./widget/widget.component";
import { WidgetDirective } from "./widget/widget.directive";
import { OneChartLayoutComponent } from "./layouts/one-chart-layout/one-chart-layout.component";
import { OneByTwoLayoutComponent } from "./layouts/one-by-two-layout/one-by-two-layout.component";
import { TwoChartsLayoutComponent } from "./layouts/two-charts-layout/two-charts-layout.component";
import { HorizontalBarChartComponent } from "./charts/horizontal-bar-chart/horizontal-bar-chart.component";
import { BarHorizontalComponent } from "./ngx-charts/bar-horizontal/bar-horizontal.component";
import { PieGridChartComponent } from "./charts/pie-grid-chart/pie-grid-chart.component";
import { PieGridComponent } from "./ngx-charts/pie-grid/pie-grid.component";
import { AuditModalComponent } from "./modals/audit-modal/audit-modal.component";
import { TwoByOneLayoutComponent } from "./layouts/two-by-one-layout/two-by-one-layout.component";
import { DeleteConfirmModalComponent } from "./modals/delete-confirm-modal/delete-confirm-modal.component";
import { TabsModule } from "./ngx-ui/tabs/tabs.module";
import { OneByTwoLayoutTableChartComponent } from "./layouts/one-by-two-layout-table-chart/one-by-two-layout-table-chart.component";
import { NavbarComponent } from "../core/navbar/navbar.component";
import { RouterModule } from "@angular/router";
import { DeleteConfirmModalDirective } from "./modals/delete-confirm-modal/delete-confirm-modal.directive";
import {
  NbActionsModule,
  NbCardModule,
  NbDialogModule,
  NbIconModule,
  NbSearchModule,
  NbSelectModule,
  NbTabsetModule,
  NbUserModule,
} from "@nebular/theme";
import { ConfirmationModalDirective } from "./modals/confirmation-modal/confirmation-modal.directive";
import { DashTrashComponent } from "./dash-trash/dash-trash.component";
import { DashEditComponent } from "./dash-edit/dash-edit.component";
import { EditDashboardModalComponent } from "./modals/edit-dashboard-modal/edit-dashboard-modal.component";
import { GeneralFilterPipe } from "./pipes/filter.pipe";
import { GeneralOrderByPipe } from "./pipes/order-by.pipe";
import { UserDataService } from "../admin_modules/admin_dashboard/services/user-data.service";
import { TabsFixturesModule } from "./ngx-ui/tabs/fixtures/tabs-fixtures.module";
import { GeneralDeleteComponent } from "./modals/general-delete-modal/general-delete-modal.component";
import { NgxPaginationModule } from "ngx-pagination";
import { CollectorItemModule } from "../viewer_modules/collector-item/collector-item.module";
import { NfrrModule } from "../screen_modules/nfrr/nfrr.module";
import { XByOneLayoutComponent } from "./layouts/x-by-one-layout/x-by-one-layout.component";
import { RefreshModalComponent } from "./modals/refresh-modal/refresh-modal.component";
import { NumberCardChartStaticAnalysisComponent } from "./charts/number-card-chart-static-analysis/number-card-chart-static-analysis.component";
import { PipelineChartComponent } from "./charts/pipeline-chart/pipeline-chart.component";
import { StackedBarchartComponent } from "./charts/stacked-barchart/stacked-barchart.component";
import { ScoreCardComponent } from "./score-card/score-card.component";
import { ScoreDetailsComponent } from "./score-details/score-details.component";
import { VerticalBarChartComponent } from "./charts/vertical-bar-chart/vertical-bar-chart.component";
import { TreeModule } from "primeng/tree";
import { TreeSelectModule } from "primeng/treeselect";
import { TreeTableModule } from "primeng/treetable";
import { CardModule } from "primeng/card";
import { BlockUIModule } from "primeng/blockui";
import { AnimateModule } from "primeng/animate";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { MultiSelectModule } from "primeng/multiselect";
import { InputTextModule } from "primeng/inputtext";
import { ToastModule } from "primeng/toast";
import { ContextMenuModule } from "primeng/contextmenu";
import { TreeTableComponent } from "src/app/shared/charts/tree-table/tree-table.component";
import { VerticalBarChartGenericComponent } from "./charts/vertical-bar-chart-generic/vertical-bar-chart-generic.component";
import { WidgetTemplateComponent } from "../screen_modules/product-dashboard/widget-template/widget-template.component";
import { NgxGaugeModule } from "ngx-gauge";
import { WidgetViewRendererComponent } from "./widget-view-renderer/widget-view-renderer.component";
import { DonutChartComponent } from "./charts/donut-chart/donut-chart.component";
import { TablePrimeComponent } from "./charts/table-prime/table-prime.component";
import { TableModule } from "primeng/table";
import { CalendarModule } from "primeng/calendar";
import { SliderModule } from "primeng/slider";
import { DropdownModule } from "primeng/dropdown";
import { ProgressBarModule } from "primeng/progressbar";
import { HttpClientModule } from "@angular/common/http";
import { BarGraphComponent } from "./charts/bar-graph/bar-graph.component";
import { DynamicCardsLayoutComponent } from "./layouts/dynamic-cards-layout/dynamic-cards-layout.component";
import { VerticalGroupChartComponent } from "./charts/vertical-group-chart/vertical-group-chart.component";
import { TableDirective } from "./tables/table.directive";
import { TableViewComponent } from "./tables/table-view/table-view.component";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PrimePanelMenuComponent } from "./charts/prime-panel-menu/prime-panel-menu.component";
import { DetailsHeaderViewComponent } from "./views/details-header-view/details-header-view.component";
import { ChipsViewComponent } from "./views/chips-view/chips-view.component";
import { DeviationChartComponent } from "./charts/deviation-chart/deviation-chart.component";
import { PercentageChartComponent } from "./charts/percentage-chart/percentage-chart.component";
import { NgxLineChartComponent } from "./charts/ngx-line-chart/ngx-line-chart.component";
import { ComboMultiBarChartComponent } from './charts/combo-multi-bar-chart/combo-multi-bar-chart.component';
import { ChartModule } from 'primeng/chart';
import { VerticalBarChartNgxComponent } from './charts/vertical-bar-chart-ngx/vertical-bar-chart-ngx.component';
import { TooltipModule } from 'primeng/tooltip';
import { NgxGaugeChartComponent } from "./charts/ngx-gauge-chart/ngx-gauge-chart.component";
import { HorizontalStackedProgressChartComponent } from './charts/horizontal-stacked-progress-chart/horizontal-stacked-progress-chart.component';
import { StackedDoughnutChartComponent } from './charts/stacked-doughnut-chart/stacked-doughnut-chart.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { RadialChartComponent } from './charts/radial-chart/radial-chart.component';
import { TableBorderedComponent } from './charts/table-bordered/table-bordered.component';
import { ApexHorizontalBarChartComponent } from './apex-charts/apex-horizontal-bar-chart/apex-horizontal-bar-chart.component';
import { ProgressSpinnerModule }  from 'primeng/progressspinner'; 
import { AppSpinnerComponent } from './app-spinner/app-spinner.component'
import { PrimeCalenderComponent } from "./charts/prime-calender/prime-calender.component";
import { TreeTableGenericComponent } from './tree-table-generic/tree-table-generic.component';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { EscalateSemetricIssuePopupComponent } from './status-bar/escalate-semetric-issue-popup/escalate-semetric-issue-popup.component';
@NgModule({
  declarations: [
    BarGraphComponent,
    GeneralDeleteComponent, // problematic
    BarHorizontalComponent,
    BaseTemplateComponent,
    CaponeTemplateComponent,
    WidgetTemplateComponent,
    ChartComponent,
    ChartDirective, // problematic
    ClickListComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    ConfirmationModalComponent,
    DashboardComponent,
    DeleteConfirmModalComponent,
    DeleteConfirmModalDirective,
    DetailModalComponent,
    DetailModalDirective,
    FormModalComponent,
    FormModalDirective,
    HorizontalBarChartComponent,
    LayoutComponent,
    LayoutDirective,
    LineAndBarChartComponent,
    LineChartComponent,
    MinutesPipe,
    NumberCardChartComponent,
    OneByTwoLayoutComponent,
    TwoChartsLayoutComponent,
    OneByTwoLayoutTableChartComponent,
    PaginationComponent,
    PieGridComponent,
    PieGridChartComponent,
    PlaceholderWidgetComponent,
    TemplatesDirective,
    TwoByTwoLayoutComponent,
    WidgetComponent,
    WidgetDirective,
    WidgetHeaderComponent,
    DashStatusComponent,
    PlainTextChartComponent,
    TwoByOneLayoutComponent,
    OneChartLayoutComponent,
    GaugeChartComponent,
    AuditModalComponent,
    TwoByOneLayoutComponent,
    NavbarComponent,
    ConfirmationModalDirective,
    DashTrashComponent,
    DashEditComponent,
    EditDashboardModalComponent,
    GeneralFilterPipe,
    GeneralOrderByPipe,
    XByOneLayoutComponent,
    RefreshModalComponent,
    NumberCardChartStaticAnalysisComponent,
    PipelineChartComponent,
    StackedBarchartComponent,
    ScoreCardComponent,
    ScoreDetailsComponent,
    VerticalBarChartComponent,
    TreeTableComponent,
    VerticalBarChartGenericComponent,
    WidgetViewRendererComponent,
    DonutChartComponent,
    TablePrimeComponent,
    PrimePanelMenuComponent,
    DynamicCardsLayoutComponent,
    VerticalGroupChartComponent,
    TableDirective,
    TableViewComponent,
    TreeTableGenericComponent,
    PrimePanelMenuComponent,
    DetailsHeaderViewComponent,
    ChipsViewComponent,
    DeviationChartComponent,
    PercentageChartComponent,
    ComboMultiBarChartComponent,
    NgxLineChartComponent,
    VerticalBarChartNgxComponent,
    NgxGaugeChartComponent,
    HorizontalStackedProgressChartComponent,
    StackedDoughnutChartComponent,
    RadialChartComponent,
    TableBorderedComponent,
    ApexHorizontalBarChartComponent,
    AppSpinnerComponent,
    PrimeCalenderComponent,
    StatusBarComponent,
    EscalateSemetricIssuePopupComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FlexLayoutModule,
    NgbModule,
    NgxChartsModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    TabsModule,
    RouterModule,
    NbActionsModule,
    NbUserModule,
    NbSearchModule,
    NbCardModule,
    NbTabsetModule,
    NbIconModule,
    NbSelectModule,
    TabsFixturesModule,
    NgxPaginationModule,
    FormsModule,
    TreeModule,
    TreeSelectModule,
    TreeTableModule,
    CardModule,
    BlockUIModule,
    AnimateModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    NgxGaugeModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DropdownModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    HttpClientModule,
    PanelModule,
    PanelMenuModule,
    ChartModule,
    TooltipModule,
    NbDialogModule.forRoot()
  ],
  exports: [
    BarGraphComponent,
    BarHorizontalComponent,
    CaponeTemplateComponent,
    WidgetTemplateComponent,
    ChartComponent,
    ChartDirective,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    CommonModule,
    HorizontalBarChartComponent,
    LayoutComponent,
    LayoutDirective,
    LineAndBarChartComponent,
    LineChartComponent,
    NumberCardChartComponent,
    OneByTwoLayoutComponent,
    TwoChartsLayoutComponent,
    OneByTwoLayoutTableChartComponent,
    PaginationComponent,
    PieGridComponent,
    PieGridChartComponent,
    ReactiveFormsModule,
    TemplatesDirective,
    TwoByTwoLayoutComponent,
    TwoByOneLayoutComponent,
    DynamicCardsLayoutComponent,
    WidgetComponent,
    WidgetDirective,
    WidgetHeaderComponent,
    GaugeChartComponent,
    NavbarComponent,
    GeneralDeleteComponent,
    DashTrashComponent,
    DashEditComponent,
    GeneralFilterPipe,
    GeneralOrderByPipe,
    NgxPaginationModule,
    CollectorItemModule,
    NfrrModule,
    NgbModule,
    NgxChartsModule,
    NgApexchartsModule,
    ScoreCardComponent,
    ScoreDetailsComponent,
    TreeTableComponent,
    DonutChartComponent,
    RadialChartComponent,
    TablePrimeComponent,
    TableViewComponent,
    TreeTableGenericComponent,
    VerticalGroupChartComponent,
    PanelMenuModule,
    PrimePanelMenuComponent,
    DetailsHeaderViewComponent,
    ChipsViewComponent,
    DeviationChartComponent,
    PercentageChartComponent,
    NgxLineChartComponent,
    ComboMultiBarChartComponent,
    VerticalBarChartNgxComponent,
    NgxGaugeChartComponent,
    HorizontalStackedProgressChartComponent,
    StackedDoughnutChartComponent,
    TableBorderedComponent,
    ApexHorizontalBarChartComponent,
    AppSpinnerComponent,
    PrimeCalenderComponent,
    ProgressSpinnerModule,
    StatusBarComponent,
    EscalateSemetricIssuePopupComponent
  ],
  providers: [UserDataService, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule { }
